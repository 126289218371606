import React from 'react';
import Layout from '../layouts/layout';
import SEO from '../components/seo';
import '../scss/_index.scss';
import ReferringOffices from '../components/ReferringOffices/ReferringOffices';

const ReferringOfficesPage = () => (
  <Layout>
    <SEO title="Patient Info" />
    <ReferringOffices />
  </Layout>
);

export default ReferringOfficesPage;
