import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/referringoffice/_referringoffice.scss';

class ReferringForm extends Component {
  render() {
    const { referringOfficesData } = this.props;
    return (
      <>
        <section className="referring-office-content h-100">
          <Container fluid className="referring-office-containter mb-5">
            <Row>
              <Col
                md={{ span: 8, offset: 2 }}
                className="text-left info-patientTitle mt-3"
              >
                {/* <p className="mt-4">
                  {
                    referringOfficesData[0].referringOffices.referralForm
                      .discriptionOne
                  }
                </p>
                <p className="mt-4">
                  {
                    referringOfficesData[0].referringOffices.referralForm
                      .discriptionTwo
                  }
                </p>
                <p className="mt-4">
                  {
                    referringOfficesData[0].referringOffices.referralForm
                      .discriptionThree
                  }
                </p> */}

                {/* <Col md={12} className="text-center">
                  <a
                    href={
                      referringOfficesData[0].referringOffices.referralForm
                        .referralFormOnlineLink
                    }
                    className="referring-office-button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .referralFormOnlineTitle
                    }
                  </a>
                </Col> */}

                <p className="mt-4">
                  {
                    referringOfficesData[0].referringOffices.referralForm
                      .discriptionFour
                  }
                </p>

                <ol>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletOne
                    }
                  </li>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletTwo
                    }
                  </li>
                </ol>

                <Col md={12} className="text-center">
                  <a
                    href={
                      referringOfficesData[0].referringOffices.referralForm
                        .referralFormPDFLink
                    }
                    className="referring-office-button"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .referralFormPDFTitle
                    }
                  </a>
                </Col>
                <p className="mt-4">
                  {
                    referringOfficesData[0].referringOffices.referralForm
                      .discriptionFive
                  }
                </p>
                {/* <h3 className="referring-office-servicesSubtitle py-2 mt-4">
                  {
                    referringOfficesData[0].referringOffices.referralForm
                      .subtitleOne
                  }
                </h3>
                <ul>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletThree
                    }
                  </li>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletFour
                    }
                  </li>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletFive
                    }
                  </li>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletSix
                    }
                  </li>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletSeven
                    }
                  </li>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletEight
                    }
                  </li>
                  <li className="pb-2">
                    {
                      referringOfficesData[0].referringOffices.referralForm
                        .bulletNine
                    }
                  </li>
                </ul> */}
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

// eslint-disable-next-line react/display-name
export default () => (
  <StaticQuery
    query={graphql`
      query ReferringFormQuery {
        allReferringofficesJson {
          nodes {
            referringOffices {
              referralForm {
                discriptionOne
                discriptionTwo
                discriptionThree
                referralFormOnlineLink
                referralFormOnlineTitle
                discriptionFour
                bulletOne
                bulletTwo
                referralFormPDFLink
                referralFormPDFTitle
                subtitleOne
                bulletThree
                bulletFour
                bulletFive
                bulletSix
                bulletSeven
                bulletEight
                bulletNine
                discriptionFive
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <ReferringForm
        referringOfficesData={data.allReferringofficesJson.nodes}
      />
    )}
  />
);

ReferringForm.propTypes = {
  referringOfficesData: PropTypes.array.isRequired,
};
