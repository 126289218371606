import React, { Component } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import '../../scss/referringoffice/_referringoffice.scss';
import ReferralForm from './ReferralForm';

class ReferringOffices extends Component {
  render() {
    return (
      <>
        <section className="referring-office-title h-100">
          <Container fluid className="referring-office-containter">
            <Row>
              <Col
                md={12}
                className="text-center referring-office-sectionTitle"
              >
                <h1>Referring Form</h1>
              </Col>
            </Row>
          </Container>
          <ReferralForm />
        </section>
      </>
    );
  }
}

export default ReferringOffices;
